
import * as THREE from "../../libs/three.js/build/three.module.js";

import {BoxVolume} from "./Volume.js";

export class Profile extends BoxVolume{

    constructor(args = {}) {
        super(args);
        this.remove(this.label);

        this.segmentDir = new THREE.Vector3(0,1,0);
		this.landmark = "02bbeaf1-3cf0-420d-87e6-a46d5d83f396";
    }

    // TODO: implement a check if bsworld is inside the box
    isInsideSphere (bsWorld) {
        return true;
    }
}
