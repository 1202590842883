
import * as THREE from "../../libs/three.js/build/three.module.js";

export const ClassificationScheme = {

	DEFAULT: {
		0:       { visible: false, name: 'never classified'  						, color: [0.5,  0.5,  0.5,  1.0] },
		1:       { visible: false, name: 'unclassified'      						, color: [0.5,  0.5,  0.5,  1.0] },
		2:       { visible: false, name: 'outlier'    								, color: [1.0,  0.0,  0.0,  1.0] },
		3:       { visible: false, name: 'Low Confidence Background'    				, color: [1.0,  0.0,  0.0,  1.0] },
		4:       { visible: false, name: 'Medium Confidence Background' 				, color: [1.0,  1.0,  0.0,  1.0] },
		5:       { visible: true, name: 'High Confidence Background'   				, color: [0.0,  1,  0.0,  1.0] },
		6:       { visible: false, name: 'Low Confidence Foreground (Silhouette)'	, color: [0.0,  1,  0.0,  1.0] },
		7:       { visible: false, name: 'Medium Confidence Foreground (Silhouette)'	, color: [0.0,  1,  0.0,  1.0] },
		8:       { visible: true, name: 'High Confidence Foreground (Silhouette)'	, color: [0.0,  1,  0.0,  1.0] },
		9:       { visible: true, name: 'Interpolated Points (Silhouette)'			, color: [0.0,  1,  0.0,  1.0] },
		DEFAULT: { visible: false, name: 'default'									, color: [0.3,  0.6,  0.6,  0.5] },
	}
};

Object.defineProperty(ClassificationScheme, 'RANDOM', {
	get: function() { 

		let scheme = {};

		for(let i = 0; i <= 255; i++){
			scheme[i] = new THREE.Vector4(Math.random(), Math.random(), Math.random());
		}

		scheme["DEFAULT"] = new THREE.Vector4(Math.random(), Math.random(), Math.random());

		return scheme;
	}
});