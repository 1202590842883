

export const KeyCodes = {

	LEFT: 37,
	UP: 38,
	RIGHT: 39,
	BOTTOM: 40,
	DELETE: 46,

	A: 'A'.charCodeAt(0),
	S: 'S'.charCodeAt(0),
	D: 'D'.charCodeAt(0),
	W: 'W'.charCodeAt(0),
	Q: 'Q'.charCodeAt(0),
	E: 'E'.charCodeAt(0),
	R: 'R'.charCodeAt(0),
	F: 'F'.charCodeAt(0),
	L: 'L'.charCodeAt(0)
	
};